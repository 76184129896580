<template>
  <div class="ledger-item-list">
    <ul>
      <li
        v-for="(item, index) of currentData"
        :key="item.id"
      >
        <div class="left">
          {{item.name}}
        </div>
        <div class="right">
          <div class="num">({{item.children ? item.children.length : 0}})</div>
          <div
            :class="`status ${item.children ? 'hasNext' : ''}`"
            @click="handleNextClick(item.children, index)"
          >下级</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import { getLocationTrees } from '@/api/deviceLedger'
  export default {
    name: "LedgerItemList",
    data () {
      return {
        LocationTreesData: [],
        currentData: []
      }
    },
    mounted() {
      this.getLocationTrees()
    },
    methods: {
      /**
       * H5设备台账获取区域树结构接口
       */
      getLocationTrees () {
        getLocationTrees().then(res => {
          this.LocationTreesData = res.content
          this.currentData = this.LocationTreesData
        })
      },
      /**
       * 下一级
       */
      handleNextClick (children, index) {
        console.log('下一级');
        if (children) {
          const locationCode = this.currentData[index].locationCode
          this.currentData = this.currentData[index].children
          this.$emit('handleGetDeviceList', locationCode)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../style/mixin";
  .ledger-item-list{
    box-sizing: border-box;
    padding: 0 0.5rem;
    background-color: white;
    border-radius: 0.1rem;
    ul{
      li{
        @include wh(100%, 2.3rem);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f1f1f1;
        position: relative;
        box-sizing: border-box;
        padding-left: 0.4rem;
        &::before{
          content: '';
          display: block;
          width: 0.15rem;
          height: 0.7rem;
          background:rgba(74,140,255,1);
          position: absolute;
          left: 0;
          top: 0.8rem;
        }
        .left{
          @include sc(0.8rem, #333333);
        }
        .right{
          display: flex;
          align-items: center;
          .num{
            @include sc(0.8rem, #333333);
          }
          .status{
            padding-left: 0.5rem;
            margin-left: 0.7rem;
            @include sc(0.8rem, #4A8CFF);
            border-left: 1px solid #f1f1f1;
            opacity:0.5;
            &.hasNext{
              opacity: 1;
            }
          }
        }
      }
    }
  }
</style>
