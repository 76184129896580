<template>
  <div class="ledger-img-list">
    <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
    >
      <div
        class="ledger-item"
        v-for="item of list"
        :key="item.id"
        @click="handleDetailClick(item.id)"
      >
        <div class="img-box">
          <img :src="imgUrl + item.photoUrl" alt="">
        </div>
        <div class="content-box">
          <h3>{{item.name}}</h3>
          <p>{{item.no}}</p>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { List } from 'vant';
  import { getDevicesWithLocation } from '@/api/deviceLedger'
  import { imgUrl } from '@/config/env'
  Vue.use(List);
  export default {
    name: "LedgerImgList",
    props: {
      locationCode: {
        type: String,
        default: ''
      }
    },
    watch: {
      locationCode: {
        handler(val) {
          if (val) {
            this.params = {
              page: 0,
              size: 10,
              locationCode: val
            }
            this.list = []
            this.finished = false
            this.getDevicesWithLocation()
          } else {
            this.finished = true
          }
        },
        immediate: true,
        deep: true
      }
    },
    data () {
      return {
        imgUrl,
        list: [],
        loading: false,
        finished: false,
        params: {
          page: 0,
          size: 10,
          locationCode: ''
        }
      }
    },
    methods: {
      /**
       * 跳转到设备详情
       */
      handleDetailClick (id) {
        this.$router.push({
          path: '/deviceDetail',
          query: {
            id
          }
        })
      },
      /**
       * 根据区域编码获取设备列表接口
       */
      getDevicesWithLocation () {
        console.log('开始');
        if (this.params.page === 0) {
          this.list = []
        }
        getDevicesWithLocation(this.params).then(res => {
          if (this.params.page === 0) {
            this.list = res.content
          } else {
            res.content.forEach(item => {
              this.list.push(item)
            })
          }
          this.params.page = this.params.page + 1
          if (this.list.length >= res.totalElements) {
            this.finished = true
          } else {
            setTimeout(() => {
              this.getDevicesWithLocation()
            }, 500)
          }
        })
      },
      /**
       * 加载更多
       */
      onLoad () {
        this.getDevicesWithLocation()
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../style/mixin";
  .ledger-img-list{
    box-sizing: border-box;
    padding: 0 0.5rem;
    background-color: white;
    border-radius: 0.1rem;
    margin-top: 1rem;
    .ledger-item{
      @include wh(100%, 3.6rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      .img-box{
        @include wh(2.3rem, 2.3rem);
        background-color: #f1f1f1;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .content-box{
        flex: 1;
        height: 100%;
        border-bottom: 1px solid #f1f1f1;
        margin-left: 0.5rem;
        box-sizing: border-box;
        padding-top: 0.8rem;
        h3{
          @include sc(0.8rem, #333);
          display: block;
        }
        p{
          @include sc(0.7rem, #999);
        }
      }
    }
  }
</style>
