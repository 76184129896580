<template>
  <div class="device-ledger-wrapper">
    <LedgerItemList @handleGetDeviceList="handleGetDeviceList" />
    <LedgerImgList :locationCode="locationCode" />
  </div>
</template>

<script>
  import LedgerItemList from './components/LedgerItemList'
  import LedgerImgList from './components/LedgerImgList'

  export default {
    name: 'DeviceMonitor',
    components: {
      LedgerItemList,
      LedgerImgList
    },
    data () {
      return {
        locationCode: ''
      }
    },
    methods: {
      /**
       * 根据区域编码获取设备列表接口
       * @param locationCode
       */
      handleGetDeviceList (locationCode) {
        console.log('接受');
        this.locationCode = locationCode
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../style/mixin";
  .device-ledger-wrapper{
    width: 100%;
    min-height: 100%;
    background-color: #F1F1F1;
    box-sizing: border-box;
    padding: 1rem;
  }
</style>
