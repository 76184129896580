import axios from '@/config/axios'

// H5设备台账获取区域树结构接口
export const getLocationTrees = () => axios({
  method: 'get',
  url: `/mobile/locationTrees`
})

// 根据区域编码获取设备列表接口
export const getDevicesWithLocation= (params) => axios({
  method: 'get',
  url: `/mobile/device/getDevicesWithLocation`,
  params
})
